@import '@crystal-eyes/constants.scss';

.container {
  width: 100%;
  padding: 16px;
  &.noPadding {
    padding: 0;
  }
}

.loader {
  text-align: center;

  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 16px;
}

.label {
  text-align: center;
  padding-top: 16px;
  font-size: 16px;
  color: $material-54;
}
