.container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  max-width: 100vw;
  margin: 0 auto;
  svg {
    width: 100%;
  }
}

.outer-1 {
  fill: #00293d;
}
.outer-2 {
  fill: #1fc3ea;
  opacity: 0.5;
}

.flake {
  fill: #1fc3ea;
  opacity: 0.1;
  animation: fade 1.2s infinite cubic-bezier(0.7, 0, 0.3, 1);
  &.one {
    animation-delay: 0s;
    transform-origin: bottom;
  }
  &.two {
    animation-delay: 0.2s;
    transform-origin: bottom left;
  }
  &.three {
    animation-delay: 0.4s;
    transform-origin: top left;
  }
  &.four {
    animation-delay: 0.6s;
    transform-origin: top;
  }
  &.five {
    animation-delay: 0.8s;
    transform-origin: right top;
  }
  &.six {
    animation-delay: 1s;
    transform-origin: right bottom;
  }
}

.inside {
  position: absolute;
  width: 100%;
  height: 100%;
}

@keyframes fade {
  0% {
    opacity: 0.1;
    transform: scale(1);
  }
  20% {
    opacity: 1;
    transform: scale(1.04);
  }
  70% {
    opacity: 0.1;
    transform: scale(1);
  }
  100% {
    opacity: 0.1;
  }
}
