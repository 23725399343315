@import '@crystal-eyes/constants.scss';

.container {
  position: relative;
  display: block;
}

.placeholder {
  transition: all 0.3s;
  display: flex;
  transition: all 0.15s;
  position: relative;
  cursor: pointer;
  user-select: none;
  border: 1px solid $material-12;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 4px;
  box-shadow: none;
  align-items: center;
  background: #fff;
  color: black;

  &.inline {
    margin: 1px 2px 0 !important;
    border: none;
    border-radius: 0px;
    border-bottom: 1px solid $material-12;
    background: none;
    color: $material-87;
    &:hover {
      box-shadow: 0 0 10px $material-12;
      border-radius: 4px;
    }
    &:focus {
      box-shadow: 0 0 2px 1px $blue;
      border-radius: 4px;
      outline: none;
    }
    .label {
      padding: 2px 4px 0px;
      font-family: var(--font-lato);
      font-weight: 400;
      font-size: 16px !important;
      color: $material-87 !important;
    }
    .down {
      display: none;
      margin: 0;
      min-width: 24px;
      max-width: 24px;
    }
  }
  &.flat {
    border-radius: 0;
    border: 1px solid #fff;
    border-bottom: 1px solid $material-08;
    margin-bottom: 0;
    transition: all 0.2s;
    padding: 4px 24px;
    .down {
      border-left: 0 solid $material-12;
    }
    form {
      width: 100%;
    }
    .label {
      width: 100%;

      padding: 0;
      height: 100%;
      display: flex;
      align-items: center;
      &:focus {
        outline: none;
      }
    }

    &.error {
      background: rgba($red, 0.1);
    }

    &:hover,
    &:focus {
      border: 1px solid $blue;
      box-shadow: none;
      outline: none;
    }
  }

  &.disabled {
    cursor: not-allowed;

    .label {
      cursor: not-allowed;
    }

    &:hover,
    &:focus {
      border: 1px solid $material-12;

      box-shadow: none;
      outline: none;
    }
  }

  &:first-child {
    margin-left: 0;
  }

  &:hover,
  &:focus {
    box-shadow: 0 0 10px $material-12;
  }

  &.full-width {
    width: 100%;
  }
}

.label {
  font-size: 18px;
  font-family: var(--font-lato);
  font-weight: 300;
  padding: 8px 16px;
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 0px solid #fff;
  background: transparent;
  cursor: pointer;
  &.light {
    color: $material-54 !important;
  }
  &.smallFont {
    font-size: 16px;
    &::placeholder {
      font-size: 16px;
    }
  }

  &.bolderLabel {
    font-family: var(--font-lato);
    font-weight: 700;
  }
}

.options {
  position: absolute;
  top: 45px;
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  overflow: hidden;
  box-shadow: 0 6px 18px 2px rgba(0, 0, 0, 0.1);
  z-index: 100001;
  background: #fff;
  width: 100%;
  margin-top: 8px;
  max-height: 300px;
  overflow-y: auto;
  min-width: 80px;

  &.flat {
    margin-top: -2px;
  }

  &.growableOptions {
    @media (min-width: 600px) {
      min-width: 100%;
      white-space: nowrap;
      width: unset;
    }
  }

  &.goUp {
    bottom: 55px;
    top: unset;
  }
}

.option {
  transition: all 0.15s;
  color: $material-87;
  padding: 8px 16px;
  font-size: 18px;
  font-family: var(--font-lato);
  font-weight: 300;
  text-align: left;
  cursor: pointer;
  user-select: none;

  &.hovered {
    background: $blue;
    color: #fff;
  }

  &.smallFont {
    font-size: 16px;
  }

  &.grey {
    color: $material-54;

    &:hover {
      background: $blue;
      color: #fff;
    }
  }

  &.spacer {
    background: $material-34;
    color: #fff;
    pointer-events: none;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 1px;
    padding: 4px 16px;
    margin: 8px 0;
  }

  &.selected {
    background: rgba($blue, 0.87);
    color: #fff;
    cursor: default;
  }
}

.down {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  min-width: 35px;
  border-left: 1px solid $material-12;
  margin: 8px 0;

  img {
    width: 20px;
    height: 20px;
    opacity: 0.34;
  }
}
