@import '@crystal-eyes/constants.scss';

@mixin background-mixin($color) {
  background: $color;
}

.container {
  border-radius: 50%;
  background: $crystal-background-gray;
  position: relative;
  border-bottom: 0;
  margin-bottom: 0;
  display: inline-block;
  position: relative;
  transition: all 0.2s;
  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
  &:hover {
    .tooltip {
      opacity: 1;
      transform: scale(1);
    }
  }
  &.shadow {
    box-shadow: 4px 3px 12px $material-12;
  }
}

.tooltip {
  opacity: 0;
  transform: scale(0.8);
  position: absolute;
  bottom: 100%;
  left: 100%;
  right: unset;
  padding: 4px 6px;
  white-space: nowrap;
  border-radius: 4px;
  pointer-events: none;
  font-size: 18px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  background-color: #fff;
  text-align: center;
  transition: all 0.1s;
  transform-origin: bottom left;

  &.tooltipLeft {
    left: unset;
    right: 100%;
  }

  @mixin background-mixin($color) {
    background-color: $color;
    color: #fff;
  }
  &.empty {
    @include background-mixin($blue);
  }
  &.D {
    @include background-mixin($disc-D);
  }
  &.Di {
    @include background-mixin($disc-Di);
  }
  &.DI {
    @include background-mixin($disc-DI);
  }
  &.Id {
    @include background-mixin($disc-Id);
  }
  &.I {
    @include background-mixin($disc-I);
  }
  &.Is {
    @include background-mixin($disc-Is);
  }
  &.IS {
    @include background-mixin($disc-IS);
  }
  &.Si {
    @include background-mixin($disc-Si);
  }
  &.S {
    @include background-mixin($disc-S);
  }
  &.Sc {
    @include background-mixin($disc-Sc);
  }
  &.SC {
    @include background-mixin($disc-SC);
  }
  &.Cs {
    @include background-mixin($disc-Cs);
  }
  &.C {
    @include background-mixin($disc-C);
  }
  &.Cd {
    @include background-mixin($disc-Cd);
  }
  &.CD {
    @include background-mixin($disc-CD);
  }
  &.Dc {
    @include background-mixin($disc-Dc);
  }
}

.profile-image {
  width: 100%;
  height: 100%;
  object-fit: cover;

  &.monochrome {
    filter: grayscale(100%);
  }

  &.background {
    @include background-mixin(#bbb);
    &.empty {
      @include background-mixin($blue);
    }
    &.d {
      @include background-mixin($disc-D);
    }
    &.i {
      @include background-mixin($disc-I);
    }
    &.s {
      @include background-mixin($disc-S);
    }
    &.c {
      @include background-mixin($disc-C);
    }

    &.D {
      @include background-mixin($disc-D);
    }
    &.Di {
      @include background-mixin($disc-Di);
    }
    &.DI {
      @include background-mixin($disc-DI);
    }
    &.Id {
      @include background-mixin($disc-Id);
    }
    &.I {
      @include background-mixin($disc-I);
    }
    &.Is {
      @include background-mixin($disc-Is);
    }
    &.IS {
      @include background-mixin($disc-IS);
    }
    &.Si {
      @include background-mixin($disc-Si);
    }
    &.S {
      @include background-mixin($disc-S);
    }
    &.Sc {
      @include background-mixin($disc-Sc);
    }
    &.SC {
      @include background-mixin($disc-SC);
    }
    &.Cs {
      @include background-mixin($disc-Cs);
    }
    &.C {
      @include background-mixin($disc-C);
    }
    &.Cd {
      @include background-mixin($disc-Cd);
    }
    &.CD {
      @include background-mixin($disc-CD);
    }
    &.Dc {
      @include background-mixin($disc-Dc);
    }
  }
}

.initials-wrapper {
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: #fff;
  font-size: 14px;
  line-height: 12px;
  font-family: var(--font-lato);
  font-weight: 700;
  letter-spacing: 0;
}

.initial-wrapper {
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: #fff;
  font-size: 14px;
  line-height: 12px;
  font-family: var(--font-lato);
  font-weight: 700;
  letter-spacing: 0;
  @include background-mixin(#bbb);
  &.d {
    @include background-mixin($disc-D);
  }
  &.i {
    @include background-mixin($disc-I);
  }
  &.s {
    @include background-mixin($disc-S);
  }
  &.c {
    @include background-mixin($disc-C);
  }

  &.D {
    @include background-mixin($disc-D);
  }
  &.Di {
    @include background-mixin($disc-Di);
  }
  &.DI {
    @include background-mixin($disc-DI);
  }
  &.Id {
    @include background-mixin($disc-Id);
  }
  &.I {
    @include background-mixin($disc-I);
  }
  &.Is {
    @include background-mixin($disc-Is);
  }
  &.IS {
    @include background-mixin($disc-IS);
  }
  &.Si {
    @include background-mixin($disc-Si);
  }
  &.S {
    @include background-mixin($disc-S);
  }
  &.Sc {
    @include background-mixin($disc-Sc);
  }
  &.SC {
    @include background-mixin($disc-SC);
  }
  &.Cs {
    @include background-mixin($disc-Cs);
  }
  &.C {
    @include background-mixin($disc-C);
  }
  &.Cd {
    @include background-mixin($disc-Cd);
  }
  &.CD {
    @include background-mixin($disc-CD);
  }
  &.Dc {
    @include background-mixin($disc-Dc);
  }
}

.content-wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  font-size: 11px;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: $material-12;
  background: $crystal-background-gray;
  border-radius: 50%;
  padding: 0 32px;
  line-height: 2em;
  transition: all 0.2s;
  opacity: 0;

  &.show {
    opacity: 1;
  }

  &.has-image {
    background: $material-34;
    color: #fff;
  }

  &.uploadPrompt {
    background: rgba(255, 255, 255, 0.54);
    color: $material-54;
    opacity: 1;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    &:hover {
      color: $material-87;
      box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
    }
  }

  &.isSmall {
    font-size: 8px;
  }
}

.input {
  display: none;
}

@media print {
  .container {
    border-radius: 50%;
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
    .profile-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .input {
    display: none;
  }
}

.svg {
  transition: all 0.1s;

  .cls-1 {
    fill: #bbb;
    opacity: 0.54;
  }
  .cls-1,
  .cls-2 {
    isolation: isolate;
  }
  .cls-2 {
    opacity: 0.08;
  }
  .cls-3 {
    fill: #fff;
  }

  &.d .cls-1 {
    opacity: 0.87;
    fill: $disc-D;
  }
  &.i .cls-1 {
    opacity: 0.87;
    fill: $disc-I;
  }
  &.s .cls-1 {
    opacity: 0.87;
    fill: $disc-S;
  }
  &.c .cls-1 {
    opacity: 0.87;
    fill: $disc-C;
  }
}

.verified-badge {
  position: absolute;
  top: 0;
  right: 0;
}
