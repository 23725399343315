@import '@crystal-eyes/constants.scss';

.container {
  width: 100%;
  margin: 0 auto 40px;
  padding: 16px 24px;
  border: 1px solid #e0e0e0;
  background-color: #fff;
  height: fit-content;
  position: relative;
  border-radius: 4px;
  text-align: left;
  &.center {
    text-align: center;
  }
  &.plain {
    padding: 0;
    border: 0px solid transparent;
    margin-bottom: 0;
  }
  &.noBorder {
    border: 0px solid transparent;
  }
  &.noMargin {
    margin-bottom: 0;
  }
  &.smallMargin {
    margin-bottom: 24px;
  }
  &.noBreaks {
    break-inside: avoid;
    page-break-inside: avoid;
  }
  &.flat {
    padding: 0;
    .title {
      font-size: 14px;
      padding: 12px 16px;
      border-bottom: 0 solid #fff;
      margin-bottom: 0;
      color: $material-38;
      background-color: $crystal-background-gray;
      text-align: center;
      text-transform: uppercase;
      letter-spacing: 1px;
    }
    .body {
      color: $material-87;
      font-family: var(--font-lato);
      font-weight: 400;
      padding: 12px 16px;
    }
  }
  &.noPadding {
    padding: 0;
    .body {
      padding: 0;
    }
  }

  &.noTopBottomPadding {
    padding-top: 0;
    padding-bottom: 0;
  }

  &.noPrint {
    @media print {
      display: none;
    }
  }
}

.title {
  display: flex;
  font-size: 24px;
  line-height: 26px;
  font-family: var(--font-lato);
  font-weight: 700;
  color: $blue;
  margin-bottom: 20px;
  padding-bottom: 22px;
  border-bottom: 1px solid #e0e0e0;
  align-items: center;
  &.noTitleMargin {
    margin-bottom: 0;
  }
  &.smallTitle {
    font-size: 18px;
    font-family: var(--font-lato);
    font-weight: 400;
    color: $material-87;
    margin-bottom: 16px;
    padding-bottom: 14px;
  }
  &.centerTitle {
    padding: 24px 16px;
    text-align: center;
    border-bottom: 1px solid $material-12;
    margin-bottom: 0;
  }
  &.paddedTitle {
    padding: 24px 16px;
  }
  &.marginTitle {
    margin: 26px 34px 20px 34px;
  }
  &.print-only {
    display: none;
    font-size: 22px;
    font-family: var(--font-lato);
    font-weight: 700;
    padding: 16px;
    margin: 0 0 -1px 0;
    color: #333;
    box-shadow: inset 0 0 0 10000px rgba(0, 0, 0, 0.04);
    @media print {
      display: flex;
      .icon {
        display: block;
        height: 26px;
        width: 26px;
      }
    }
  }
}

.boldTitle {
  font-family: var(--font-lato);
  font-weight: 700;
}

.icon {
  margin-right: 16px;
  padding: 2px;
  opacity: 0.87;
  height: 24px;
  width: 24px;
}

.text {
  flex: 1;
}

.action {
  color: $blue;
  font-size: 16px;
  cursor: pointer;
  line-height: 1.4em;
  weight: 400;
  &:hover {
    text-decoration: underline;
  }
}

.body {
  height: auto;
  &.center-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  &.marginTop {
    margin-top: 20px;
  }
}

@media print {
  .container {
    margin: 20px auto 32px;
    page-break-inside: avoid;
    break-inside: avoid;

    &.allowBreaks {
      page-break-inside: auto;
      break-inside: auto;
    }
  }
  .title {
    font-size: 18px;
  }
  .icon {
    display: none;
  }
}
